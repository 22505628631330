@mixin elegant-font { @include text-shadow(none); -webkit-font-smoothing: antialiased; font-family: 'ElegantIcons'; speak: none; font-weight: normal; font-variant: normal; line-height: 1; text-transform: none; }
@mixin font-awesome { @include text-shadow(none); -webkit-font-smoothing: antialiased; font-family: 'fontawesome'; speak: none; font-weight: normal; font-variant: normal; line-height: 1; text-transform: none; }
@mixin transit { @include transition( 0.3s ease ); }
@mixin shadow { @include box-shadow(0px 1px 2px rgba(0,0,0,.2)); }
@mixin shadow-big { @include box-shadow( 0px 3px 15px rgba(0, 0, 0, 0.33) ); }
@mixin shadow-text { @include text-shadow(0px 1px 1px rgba(0,0,0,.5)); }
@mixin uppercase { text-transform: uppercase; font-size: 10px; font-weight: 700; }
@mixin gradient-black {
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 );
}
@mixin button-overlay ($opacity) { @include transit; position: relative; border-color: rgba(#000, $opacity );
  &:active, &:focus, &:hover, &:active:focus, &:active:hover { border-color: rgba(#000, ($opacity + .1) );
    &:after { background-color: rgba(#000, ($opacity + .1)); }
  }
  &:after { @include transit; background-color: rgba(#000, $opacity); width: 100%; height: 100%; content: ""; position: absolute; top: 0; left: 0; z-index: -1; }
}
@mixin button-overlay-hover () {
  &:active, &:focus, &:hover, &:active:hover, &:active:focus, &:focus:hover { background-color: $color-default;
    &:after { background-color: rgba(#000, .2 ); }
  }
  &:after { @include transit; content: ""; background-color: rgba(#000, 0); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; }
}

@mixin darker-background ($opacity) {
  &:after { @include transit; content: ""; background-color: rgba(#000, $opacity); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0; }
}

@mixin darker-background-hover ($opacity) {
  &:after { @include transit; content: ""; background-color: rgba(#000, 0); position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; }
  &:active, &:focus, &:hover, &:active:hover, &:active:focus, &:focus:hover {
    &:after { background-color: rgba(#000, $opacity); }
  }
}

@mixin keyframe ($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation ($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count: infinite;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -moz-animation-iteration-count: infinite;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -o-animation-iteration-count: infinite;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-iteration-count: infinite;
}
