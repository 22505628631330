body{
  .primary-nav, .secondary-nav { display: inline-block;  margin-right: 15px; padding-right: 15px; position: relative; border-right: 1px solid rgba(#000,.1);
    &.has-mega-menu { position: initial;
      .mega-menu-parent { position: initial; display: inline-block;
        &:hover {
          .mega-menu, .mega-menu:hover { visibility: visible; @include opacity(1); pointer-events: auto; }
        }
        .mega-menu { visibility: hidden; pointer-events: none; position: absolute; right: -7px; text-align: left; top: -3px;
          &:hover { visibility: visible; @include opacity(1); pointer-events: auto; }
          .nav-wrapper { @include transit; background-color: #fff; }
          h4 { border-bottom: 1px solid rgba(#000, .1); padding-bottom: 15px; margin-bottom: 10px; margin-top: 0; }
          ul {
            li { @include border-radius(0); display: block; background-color: transparent; border: none;
              a { @include opacity(.7); @include border-radius(0px); border: none; padding: 3px 0 !important; display: block; font-weight: bold; display: inline-block;
                &:hover { @include opacity(1); color: $color-default; }
              }
            }
          }
          .container { padding-left: 30px; padding-right: 0px; padding-top: 30px; padding-bottom: 30px;
            .row { display: table; width: 100%; height: 100%;
              > [class*=" col-"] { display: table; height: 100%; }
            }
          }
          .image { width: 100%; height: 100%;  position: relative; display: table; z-index: 1; }
          .btn { z-index: 1; }
        }
        .wrapper { right: 0 !important; left: inherit !important; }
      }
    }
    // 1st level
    ul {
      &.navigation { list-style: none; margin-bottom: 0;
        > li {
          &.has-child {
            &:hover {
              > a { color: $color-default; border: 2px solid rgba(#000, .2);
                &:before { @include opacity(0); }
                &:after { @include opacity(.5); }
              }
              .wrapper { @include opacity(1); @include transform( translateY(0px) ); pointer-events: auto; }
            }
          }
        }
        li { display: inline-block; position: relative; text-align: left;
          &.has-child {
            > a { padding-right: 20px;
              &:before { @include font-awesome; @include transition(); @include opacity(.5); color: #000; content: "\f078"; position: absolute; font-size: 10px; right: 0; top: 0; bottom: 0; margin: auto; height: 11px; padding-right: 7px; }
              &:after { @include font-awesome; @include transition(); @include opacity(0); color: #000; content: "\f077"; position: absolute; font-size: 10px; right: 0; top: 0; bottom: 0; margin: auto; height: 11px; padding-right: 7px; }
            }
            > .wrapper { @include opacity(0); @include transit; @include transform( translateY(5px) ); pointer-events: none; position: absolute; right: 0; top: 100%; z-index: 1; /****/ right: inherit; left: 0;
              > .nav-wrapper, .mega-menu .nav-wrapper { @include box-shadow( 0px 3px 15px rgba(0, 0, 0, 0.20)); padding-left: 0; margin-top: 10px; min-width: 150px;
                > ul {
                  > li {
                    &:first-child { position: relative;
                      &:hover {
                        &:after { border-color: transparent transparent #f2f2f2 transparent; }
                      }
                      &:after { @include opacity(1); @include transit; width: 0; height: 0; border-style: solid; border-width: 0 5px 8px 5px; border-color: transparent transparent #fff transparent; position: absolute; z-index: 1; top: -6px; left: 12px; content: ""; }
                    }
                  }
                }
              }
            }
            .nav-wrapper {
              i { margin-right: 10px; }
            }
          }
          // 2nd level
          ul { padding-left: 0;
            li { @include transit; display: block; white-space: nowrap; border-bottom: 1px solid rgba(#000, .1); background-color: #fff;
              &:last-child { border-bottom: none; }
              &:hover { background-color: #f2f2f2; color: $color-default;
                ul {
                  li { background-color: transparent;
                    &:hover { background-color: rgba(#000, .05); }
                  }
                }
                > .nav-wrapper { @include opacity(1); pointer-events: auto; }
              }
              &.has-child {
                > a {
                  &:before { content: "\f054"; }
                }
              }
              .nav-wrapper { @include transform(translateX(100%)); @include shadow-big; @include opacity(0); @include transit; background-color: #fff; pointer-events: none; position: absolute; z-index: 1; right: 0; min-width: 150px;  top: 0;}
              ul {
                li {
                  &:first-child { border-top: 1px solid rgba(#000, .1); }
                }
              }
              a { display: block !important; padding: 6px 8px !important;
                &:hover { color: $color-default; }
              }
            }
          }
        }
      }
    }
    a {
      i { @include opacity(.7); margin-right: 5px; }
    }
  }
  .secondary-nav ul.navigation li.has-child > .wrapper { left: inherit; right: 0; }
  .secondary-nav ul.navigation li.has-child > .wrapper > .nav-wrapper > ul > li:first-child::after { left: inherit; right: 10px; }
}

#page-header { z-index: 999; position: relative; width: 100%;
  nav { @include shadow; display: table; width: 100%; background-color: $color-grey-light; padding: 6px 7px; position: relative; z-index: 999; white-space: nowrap; }
  .left, .right { display: table-cell; vertical-align: middle; }
  .right { text-align: right; position: relative; }
  .secondary-nav { display: inline-block; margin-right: 10px;
    .image { @include border-radius(100%); position: relative; width: 30px; height: 30px; display: inline-block; vertical-align: middle; overflow: hidden; float: right; margin-bottom: -3px; }
  }
  .secondary-nav, .primary-nav {
    a:not(.btn) { @include opacity(.9); @include border-radius(30px); display: inline-block; text-transform: uppercase; font-weight: bold; font-size: 11px; color: $color-grey-dark; margin: 0; border: 2px solid rgba(#000, 0); padding: 4px 6px;
      &:hover { color: $color-default; }
      &.promoted { color: $color-default; font-weight: 800; }
    }
    a:not(.btn), > a:not(.btn):hover {   }
    .navigation { display: inline-block; margin-bottom: 0; list-style: none; }
    li.has-child > a { padding-right: 20px; padding-left: 10px; }
  }
  .primary-nav, .secondary-nav {
    > ul {
      > li.active {
        > a { @include transform-style(preserve-3d); @include opacity(1); color: $color-default; border: 2px solid rgba(#000, .2); }
      }
    }
  }
  .nav-btn { @include transit; @include border-radius(3px); display: none; padding: 8px; cursor: pointer; position: absolute; top: -2px; bottom: 0; height: 28px; right: 0; margin: auto; background-color: transparent; @include box-shadow(inset 0px 0px 0px 2px rgba(#000, .4));
    &:hover, &.active { background-color: $color-default;
      i { background-color: #fff;
        &:last-child { width: 12px; }
      }
    }
    i { @include transit; height: 2px; width: 12px; background-color: #fff; margin-bottom: 2px; display: block; background-color: $color-default;
      &:last-child { width: 10px; margin-bottom: 0px; }
    }
  }
  .btn {
    &.icon {
      i { margin-left: 0; }
    }
  }
}
//body .primary-nav.has-mega-menu .mega-menu-parent .mega-menu .nav-wrapper, body .secondary-nav.has-mega-menu .mega-menu-parent .mega-menu .nav-wrapper
body.nav-btn-only {
  #page-header {
    .nav-btn { display: block; }
    .right { padding-right: 40px; }
    .primary-nav { @include border-radius(3px); @include transition( .2s transform ); @include transform(translateY(5px)); @include opacity(0); @include box-shadow( 0px 3px 15px rgba(0, 0, 0, 0.33) ); visibility: hidden; position: absolute; top: 100%; margin-top: 6px; background-color: $color-default; padding-right: 0; margin-right: 0; border: 0; right: 0;
      &.show { visibility: visible; @include transform(translateY(0px)); @include opacity(1); }
      &.has-mega-menu { position: absolute;
        .mega-menu-parent { position: relative; display: block;
          .mega-menu { @include opacity(1); background-color: rgba(#000,.1); visibility: visible; pointer-events: auto; position: relative; right: 0; text-align: inherit; padding-top: 0;
            .wrapper { @include box-shadow(none); background-color: inherit;  padding-top: 0px; padding-bottom: 0px; }
            h4 { border-bottom: none; padding-bottom: 0px; margin-bottom: 0px; margin-top: 0px; color: #fff; font-weight: 800;  font-size: 11px; padding-top: 2px; padding-bottom: 2px; }
            a {  font-weight: 800;
              &:hover { background-color: rgba(#000,.2); }
              &.has-child { padding-right: 25px;
                &:before { @include font-awesome; @include transition(); @include opacity(.5); color: #fff; content: "\f078"; position: absolute; font-size: 10px; right: 0; top: 0; bottom: 0; margin: auto; height: 11px; padding-right: 7px; }
                &:after, &:before { color: #fff; }
              }
            }
            ul { background-color: rgba(#000,.1);
              li {
                a { display: block; padding: 8px 10px; }
              }
            }
            .container { padding-left: 0px; padding-right: 0px; width: auto; padding-top: 0; padding-bottom: 0;
              .row { display: block; width: inherit; height: inherit; padding-right: 0px; padding-left: 0px;
                > [class*=" col-"] { display: block; height: 100%; width: 100%; clear: both; margin-bottom: 0px;
                  &:last-child { margin-bottom: 0; }
                  &:first-child {
                    > a { border-top: 1px solid rgba(#fff, .1); }
                  }
                  a { border-bottom: 1px solid rgba(#fff, .1);  }
                }
              }
            }
            .image { width: 100%; height: 100%;  position: relative; display: table; z-index: 1; }
            .btn { z-index: 1; }
            .nav-wrapper { background-color: transparent; }
          }
        }
      }
      ul.navigation {
        > li {
          &:first-child {
            &:after { @include transit; width: 0; height: 0; border-style: solid; border-width: 0 5px 8px 5px; border-color: transparent transparent $color-default transparent; position: absolute; z-index: 1; top: -6px; right: 8px; content: ""; }
          }
          &.has-child, &.has-child:hover {
            a { border: none; color: #fff; }
            .wrapper { @include opacity(1); @include transform( translateY(0) ); }
            > .wrapper > .nav-wrapper > ul > li:first-child::after { display: none; }
          }
        }
        li {
          ul {
            li {
              a { padding: 10px 10px !important; }
              .nav-wrapper { @include opacity(1); @include transform( translateX(0) ); background-color: transparent; }
            }
          }
        }
      }
      ul { padding-left: 0; min-width: 220px;
        li { @include transit; display: block; border-bottom: 1px solid rgba(#fff, .1);
          &:hover { background-color: rgba(#000,.2);
            a { border: none; }
          }
          &.active { background-color: rgba(#000,.2);
            a { border: none; color: #fff; }
          }
          &.has-child {
            > a { padding-right: 25px;
              &:before { @include font-awesome; @include transition(); @include opacity(.5); color: #fff; content: "\f078"; position: absolute; font-size: 10px; right: 0; top: 0; bottom: 0; margin: auto; height: 11px; padding-right: 7px; }
              &:after, &:before { color: #fff; }
              &:hover {
                &:before { @include opacity(0); }
              }
            }
          }
          .wrapper { position: relative; padding-top: 0; }
          a { @include border-radius(1px); font-weight: 800; padding: 10px; display: block; border: none; color: #fff; position: relative;
            &:hover { color: #fff; }
            &.has-child { padding-right: 25px;
              &:before, &:after { color: #fff; padding-right: 10px; }
              &[aria-expanded=true] { background-color: rgba(#000,.2); }
            }
          }
          .nav-wrapper { @include box-shadow(none); margin-top: 0; position: relative;
            ul { background-color: rgba(#000,.2);
              li { background-color: transparent; border-bottom: 1px solid rgba(#fff, .1);
                &:first-child { border-top: 1px solid rgba(#fff, .1); }
                &:hover { background-color: rgba(#000,.2); }
              }
            }
          }
        }
      }
    }
  }
}


.hero-section { position: relative;
  &.has-background {
    h1 { @include opacity(1); color: #fff; font-weight: normal; font-size: 54px; }
    h2 { @include opacity(.5); color: #fff; font-size: 24px; font-weight: normal; }
    h4 { color: #fff; }
    .slider { height: 100%; width: 100%; position: absolute; top: 0;
      .owl-carousel, .owl-stage-outer, .owl-stage, .owl-item { height: 100%; }
      .owl-nav, .owl-dots { position: absolute; }
      .owl-controls { text-align: center; }
      .slide { display: table; height: 100%; width: 100%; }
      [data-owl-dots="1"] .owl-dots {
        display: inline-block !important; bottom: 10px;
      }
      .presentation { width: 100%; height: 100%;
        &:after { @include gradient-black; width: 100%; height: 50%; position: absolute; bottom: 0; left: 0; content: ""; }
        .description { position: absolute; width: 100%; bottom: 200px; z-index: 1;
          h1 { @include text-shadow(0px 2px 12px rgba(0,0,0,.5)); font-size: 72px; font-weight: bold; }
          h2 { @include opacity(.8); font-size: 18px; font-weight: bold;
            i { margin-right: 10px; }
          }
          hr { border-top: 2px solid rgba(#fff, .2); margin-top: 20px; margin-bottom: 20px; }
          .label, .rating-passive { display: inline-block; margin-right: 20px; }
          .label { padding: 6px; background-color: #fff; color: #000; }
          .rating-passive {
            .stars {
              figure { color: #fff; font-size: 16px; }
            }
          }
        }
        .bg-transfer {
          &:after { @include opacity(.1); position: absolute; width: 100%; height: 100%; background-color: #000; content: ""; }
        }
      }
    }
    form { z-index: 1; }
  }
  &.has-map {
    .results-wrapper {
      .search-form { position: relative; height: auto; padding: 20px;
        form { @include border-radius(4px); @include box-shadow(none); background-color: rgba(#000,.03); padding: 10px 20px; display: table; height: 100%; width: 100%; }
        .section-title {
          h2 { font-size: 18px; margin-left: 0; font-weight: normal; color: inherit; }
        }
        .form-group { margin-bottom: 10px; display: table; height: auto; width: 100%; position: relative; }
        .btn { padding: 8px; }
        &.inputs-underline {
          input[type="text"],input[type="email"], input[type="date"], input[type="time"], input[type="search"], input[type="password"], input[type="number"], input[type="tel"], textarea.form-control { padding-left: 0; color: inherit;
            &:active, &:focus { @include box-shadow(none); padding-left: 8px; border-bottom: 2px solid rgba(#000,.15); }
          }
        }
        input[type="text"],input[type="email"], input[type="date"], input[type="time"], input[type="search"], input[type="password"], input[type="number"], input[type="tel"], textarea.form-control { padding: 8px; color: inherit; }
        .ui-slider { margin-top: 2px;
          .noUi-base {
            .noUi-handle { border: 2px solid $color-default; background-color: #fff;
              &:hover, &.noUi-active { @include box-shadow(0px 0px 0px 8px rgba(#000,.07)); }
            }
          }
        }
        .btn[type="submit"] { @include border-radius(100%); position: absolute; width: 45px; height: 45px; bottom: -40px; right: 0px;
          &:before { display: none; }
          &:after { @include border-radius(100%); }
          &:active { top: inherit; }
          i { margin-left: 0; margin-top: -1px; }
        }
      }
    }
  }
  &.has-sidebar {
    &.sidebar-grid {
      .map-wrapper, .results-wrapper { width: 50%; }
      .results-wrapper .sidebar-detail { z-index: 22; }
      .results-content {
        .item { float: left; width: 44.8%; margin: 10px 10px 20px 20px; height: 270px;
          .description { z-index: 1; }
          .image { background-size: cover;
            .price { right: inherit; left: 10px; }
          }
        }
      }
    }
  }
  .coupon { display: table-cell; vertical-align: middle;
    div { height: auto; }
    h1 { font-size: 36px; font-weight: bold; }
    .wrapper { display: inline-block; text-align: left; width: 900px; position: relative; }
    .image { position: absolute; left: 0;
      .circle { @include shadow; @include border-radius(100%); width: 90px; height: 90px; color: #fff; font-weight: bold; left: 0; top: 0; background-color: $color-default; font-size: 24px; text-align: center; line-height: 86px; }
      .bg-transfer { @include border-radius(100%); width: 300px; height: 300px; }
    }
    .description { padding-left: 330px; padding-top: 10px;
      hr { @include opacity(.3); border-top: 2px solid #fff; margin-bottom: 20px; margin-top: 20px; }
      figure { display: inline-block; margin-right: 20px; }
      .location {
        i { margin-right: 10px; }
      }
      .label { text-transform: none; font-size: 14px; font-weight: bold; }
      .count-down { margin-bottom: 20px;
        .countdown-row {
          .countdown-amount { font-size: 36px; }
        }
      }
    }
  }
  > .wrapper { display: table; height: 100%; width: 100%; z-index: 2; position: relative;
    > .inner { display: table-cell; vertical-align: middle; }
  }
  .map-wrapper { width: 100%; display: inline-block; position: relative; z-index: 1; }
  .results-wrapper { @include transition(0.7s ease); @include box-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)); position: relative; float: right; display: inline-block; background-color: #fff; height: 100%; overflow: hidden;
    &.show-detail {
      // .results { @include transform(translateX(-100%)); }
      .main-sidebar-detail { @include transform(translateX(0%)); }
    }
    &.loading {
      .sidebar-detail, .results { @include opacity(.5);  }
    }
    h2 { margin: 10px 20px; font-size: 30px; display: inline-block;
      .results-number { margin-left: 10px; font-size: 22px; color: rgba(#000, .4); display: inline-block;
        &:before { content: "(" }
        &:after { content: ")" }
      }
    }
    .section-title { margin-top: 0; margin-bottom: 0;
      .full-detail { float: right; margin-top: 10px; }
    }
    .results { @include transition(.7s ease); @include transform-style(preserve-3d); position: relative; height: 100%; }
    .result-item { position: relative; display: table; width: 100%; height: 100%;
      > a { color: inherit; position: relative; display: table; width: 100%; height: 100%; padding-left: 25px; padding-right: 25px;
        &:before { @include opacity(0); @include transit; position: absolute; top: 0; left: 0; content: ""; width: 5px; height: 100%; background-color: $color-default; }
        &:hover, &.hover-state, &.active { background-color: rgba(#000,.05);
          &:before { @include opacity(1); }
        }
        h3 { font-size: 18px; font-weight: bold; margin-bottom: 15px; }
        h4 { @include opacity(.8); font-size: 12px; font-weight: normal; margin-top: 0; }
        .result-item-detail { position: relative; border-bottom: 1px solid rgba(#000,.1); display: table; padding-bottom: 15px; width: 100%;
          .image { @include shadow; color: #fff; width: 125px; height: 100px; background-size: cover; top: 0; left: 0; text-align: center; float: left; clear: both; position: relative;
            &:after { @include opacity(.7); @include gradient-black; background-color: rgba(#000, .2); position: absolute; bottom: 0; left: 0; height: 100%; width: 100%; content: ""; }
            figure { position: absolute; bottom: 5px; z-index: 1; font-size: 12px; font-weight: bold; padding: 5px; width: 100%; }
          }
          .description { padding-left: 140px;
            h5 { font-size: 14px; margin-bottom: 5px; margin-top: 0;
              i { @include opacity(.3); margin-right: 5px; font-size: 12px; }
            }
            p { font-size: 12px; line-height: 16px; max-height: 32px; overflow: hidden; margin-bottom: 0; }
            .label { @include box-shadow(none); color: rgba(#000,.5); border: 1px solid rgba(#000, .2); background-color: transparent; margin: 3px 0; display: inline-block; margin: 8px 0; }
          }
        }
      }
      .ribbon { right: 10px; font-size: 9px; }
      .controls-more { padding: 1px 15px; z-index: 5;
        a { padding: 7px 10px; }
        ul { right: 15px; }
      }
    }
    .tse-scrollable { width: 100%; height: 100%; }
    .tse-scrollbar .drag-handle { right: 4px; width: 5px; }
    .tse-scrollbar .drag-handle.visible { @include opacity(.4); }
    .image, .gallery-wrapper {
      .price { @include shadow; background-color: $color-grey-light; font-weight: 900; color: $color-grey-dark; position: absolute; left: -6px; top: 10px; z-index: 2; padding: 5px; font-size: 12px;
        &:after { width: 0; height: 0; border-style: solid; border-width: 0 6px 6px 0; border-color: transparent #ababab transparent transparent; content: ""; position: absolute; bottom: -6px; left: 0; }
      }
    }
  }
}

@include keyframe(pulse) {
  0% {
    @include transform(scale(.8)); @include opacity(0);
  }
  10% {
    @include opacity(1);
  }
  100% {
    @include transform(scale(2)); @include opacity(0);
  }
}

.map, .map-wrapper { height: 100%; }

.map-wrapper {
  .geo-location { @include shadow; @include transit; position: absolute; height: 30px; width: 30px; background-color: $color-default; color: $color-white; font-size: 18px; text-align: center; line-height: 30px; right: 20px; top: 20px; z-index: 1; cursor: pointer;
    &:hover { background-color: $color-white; color: $color-default; }
  }
}

.map {
  &.show-marker-labels {
    .marker {
      .title { @include opacity(1); }
    }
  }
  .cluster {
    &:hover {
      > div { @include transform( rotateY( 180deg ) ); }
    }
    > div { @include border-radius(100%); @include transform-style(preserve-3d); @include transition(0.5s cubic-bezier(0,.28,.45,1.01)); @include box-shadow( 0px 1px 2px rgba(0,0,0,.2), inset 0px 0px 0px 2px rgba(#fff,.3) ); background-color: $color-default; border: 2px solid $color-default; width: 36px; height: 36px; color: #fff !important; line-height: 32px !important;
      &:after { @include border-radius(100%); @include transform(translateZ(-2px) ); @include box-shadow( inset 0px 0px 0px 2px rgba(#fff,.3) ); border: 2px solid $color-default; background-color: $color-default; width: 36px; height: 36px; position: absolute; top: -2px; left: -2px; content: ""; }
      &:before { @include font-awesome; @include transform(translateZ(-4px) ); content: "\f002"; color: #fff; position: absolute; top: 0; left: 0; width: 33px; height: 36px; line-height: 30px; font-size: 14px; text-align: center; }
    }
  }
  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}
  .gmnoprint a, .gmnoprint span, .gm-style-cc { display:none; }
  .marker { display: inline-block; cursor: pointer; position: relative;
    &:after { @include border-radius(50%); @include transit; @include scale(.7); width: 14px; height: 4px; content: ""; position: absolute; bottom: 0; left: 0; right: 0; margin: auto; background-color: rgba(#000, .3); z-index: 0;}
    &:hover, &.hover-state, &.active {
      &:after { @include scale(1); }
      .marker-wrapper { @include transform( rotateY( 180deg ) translateY(-4px) );
        .pin {
          &:after { @include transform(translateX(-1px) ); @include transit; }
        }
      }
      .title { @include opacity(1); @include transform(translateX(0px)); }
    }
    &.active {
      .marker-wrapper {
        // &:before { @include animation(0s, 2s, pulse);  }
      }
    }
    .marker-wrapper { @include transition(0.5s cubic-bezier(0,.28,.45,1.01)); position: relative; transform-style: preserve-3d; width: 37px; height: 49px;
      &:before { @include border-radius(100%); background-color: $color-default; width: 37px; height: 37px; position: absolute; top: 0; left: 0; content: ""; z-index: -1; }
      &:after { @include font-awesome; @include transform(translateZ(-4px) ); content: "\f002"; color: #fff; position: absolute; top: 0; left: 0; width: 37px; height: 37px; line-height: 34px; font-size: 14px; text-align: center; }
      .pin { @include border-radius(100%); @include box-shadow(0px 1px 2px rgba(0,0,0,.2), inset 0px 0px 0px 2px #fff ); transform-style: preserve-3d; border: 2px solid $color-default; position: absolute; top: 0; left: 0; width: 37px; height: 37px; background-size: cover;
        &:before { @include transform(translateZ(-2px) ); @include box-shadow(inset 0px 0px 0px 2px rgba(#fff,.3) ); @include border-radius(100%); border: 2px solid $color-default; content: ""; z-index: -2px; background-color: $color-default; position: absolute; top: -2px; left: -2px; width: 37px; height: 37px; z-index: 1; }
        .image { @include border-radius(100%); @include box-shadow(0px 1px 2px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 2px #fff); background-size: cover; height: 100%; width: 100%; position: relative; z-index: 2;
          &:after { width: 0; height: 0; border-style: solid; border-width: 8px 4px 0 4px; border-color: $color-default transparent transparent transparent; content: ""; position: absolute; bottom: -8px; left: 0; right: 0; margin: auto; }
        }
      }
    }
    .tag { @include shadow; @include backface-visibility(hidden); @include border-radius(100%); @include transform(translateZ(3px)); position: absolute; top: -3px; right: -1px; z-index: -1; background-color: $color-default; width: 19px; font-size: 9px; height: 19px; color: #fff; text-align: center; line-height: 18px; transform-style: preserve-3d;
      i { @include transform(translateZ(2px)); transform-style: preserve-3d; }
    }

    .title { @include opacity(0); @include transform(translateX(10px)); @include border-radius(1px); @include transit; @include box-shadow( 0px 1px 4px rgba(0, 0, 0, 0.25)); pointer-events: none; position: absolute; left: 50px; top: 5px; white-space: nowrap; background-color: #fff; padding: 2px 4px;
      &:after { width: 0; height: 0; border-style: solid; border-width: 4px 6px 4px 0; border-color: transparent #fff transparent transparent; content: ""; position: absolute; left: -6px; top: 0; bottom: 0; margin: auto; }
      &:before { width: 0; height: 0; border-style: solid; border-width: 4px 6px 4px 0; border-color: transparent rgba(#000,.2) transparent transparent; content: ""; position: absolute; left: -6px; top: 2px; bottom: 0; margin: auto; }
    }
  }

  .tooltip {
    .tooltip-inner { @include shadow; white-space: nowrap; @include transform(translateY(-5px)); background-color: #fff; color: $color-grey-dark; }
    .tooltip-arrow { @include transform(translateY(-5px)); border-top-color: rgba(#000,.3); z-index: 1; bottom: -1px;
      &:after { width: 0; height: 0; border-color: transparent; border-style: solid; border-width: 5px 5px 0; border-top-color: #fff; position: absolute; top: -6px; left: -5px; content: ""; }
    }
  }
}

.sidebar-detail { @include transition(.7s ease); @include transform(translateX(100%)); @include transform-style(preserve-3d); background-color: #fff; position: absolute; left: 0; top: 0; z-index: 1; height: 100%; width: 100%; padding: 10px 0;
  section { padding-bottom: 15px; border-bottom: 1px solid rgba(#000,.08); margin-bottom: 15px; margin-top: 15px;
    &:last-child { border-bottom: none; margin-bottom: 0; }
  }
  h3 { font-size: 18px; font-weight: normal; color: inherit; margin-bottom: 20px; }
  .gallery-wrapper { height: 100%; position: relative;
    .price { font-size: 14px !important; padding: 5px 10px !important; }
  }
  .gallery { height: 100%;
    .owl-stage-outer { height: 100%; }
  }
}
.sidebar-detail, .modal {
  .sidebar-wrapper { padding: 0 20px; }
  .section-title { margin-bottom: 15px; position: relative; margin-top: 10px;
    h2 { margin-left: 0; margin-top: 0;; margin-bottom: 0;
    }
    h4 { @include opacity(.6); font-size: 14px; margin-bottom: 5px; margin-top: 5px; }
  }
  h5 { font-size: 14px;
    i { color: rgba(#000,.2); margin-right: 10px; }
    a { color: inherit; }
  }
  .label { margin-bottom: 10px; display: inline-block; padding: 5px; margin-right: 15px; margin-top: 5px; }
  .rating-passive { display: inline-block; }
  .owl-dots { text-align: left; }
  .back { @include border-radius(100%); @include transit; cursor: pointer;  border: 2px solid rgba(#000, .1); width: 40px; height: 40px; margin-right: 15px; position: relative;
    &:after { @include elegant-font; position: absolute; top: 0; left: 0; content: "\#"; width: 20px; height: 20px; width: 100%; line-height: 36px; font-size: 22px; text-align: center; color: $color-default; }
    &:hover { border: 2px solid rgba(#000, .3);  }
  }
  .gallery { @include opacity(1); @include transit; margin: 20px 0;
    &.show { @include opacity(1); }
    img { display: block; position: relative;
      &:after { @include opacity(.7); @include gradient-black; position: absolute; bottom: 0; left: 0; height: 100%; width: 100%; content: ""; }
    }
  }
  .ribbon { top: inherit; bottom: 0; }
  .controls-more { margin: inherit; bottom: inherit; top: 5px; z-index: 5;
    ul { bottom: inherit; top: 35px;
      li {
        &:first-child {
          a {
            &:after { @include transit; width: 0; height: 0; border-style: solid; border-width: 0 0 11px 11px; border-color: transparent transparent #fff transparent; content: ""; position: absolute; right: 0px; top: -10px; bottom: inherit; height: 11px; width: 11px; }
            &:before { width: 0; height: 0; border-style: solid; border-width: 0 0 15px 14px; border-color: transparent transparent rgba(#000, .05) transparent; content: ""; position: absolute; right: -2px; top: -15px; bottom: inherit; height: 11px; width: 11px; }
            &:hover {
              &:after { border-color: transparent transparent #e8e8e8 transparent;  }
            }
          }
        }
        &:last-child {
          a {
            &:before, &:after { display: none; }
          }
        }
      }
    }
  }
  .jssocials-share { padding: 1px 3px 2px 3px;
    .jssocials-share-logo { font-size: 1em; }
  }
}

.infobox-wrapper { @include transition(0.5s cubic-bezier(0,.28,.45,1.01)); @include opacity(0);
  &.show { @include opacity(1);
    > img { @include transform( scale(1) ); }
    .infobox {
      > a { @include transform( translateY(0px)); }
      .rating-passive { @include opacity(1); @include transform( translateX(0px)); @include transition-delay(.2s); }
      .description { @include opacity(1); @include transform( translateX(0px)); @include transition-delay(.1s); }
    }
  }
  > img { @include transit; @include transform( scale(0) ); position: absolute !important; z-index: 1; right: -15px; top: -15px; }
  .infobox { width: 270px;
    .description { @include transition(0.5s cubic-bezier(0,.28,.45,1.01)); @include opacity(0); @include transform( translateX(-10px)); z-index: 1; bottom: 40px; padding-right: 20px;
      h3 { font-size: 18px;  }
      h4 { font-size: 12px; }
    }
    .rating-passive { @include transition(0.5s cubic-bezier(0,.28,.45,1.01)); @include opacity(0); @include transform( translateX(-10px));
      position: absolute; bottom: 15px; left: 20px; padding: 0; background-color: transparent; color: #fff; display: inline-block;
      .stars { margin-right: 2px;
        figure { color: #fff; }
      }
    }
    > a { @include border-radius(4px); @include box-shadow( 0px 3px 15px rgba(0, 0, 0, 0.33) ); @include transition(0.5s cubic-bezier(0,.28,.45,1.01)); @include transform( translateY(15px)); @include transform-style(preserve-3d);
      .image { background-position: 50% 50%; background-size: cover;
        &:after { @include opacity(.7); background-color: transparent; }
      }
    }
    .controls-more { right: 15px; top: inherit; bottom: 10px;
      &:hover {
        &:after { @include opacity(1); color: #fff;  }
      }
      &:after { @include opacity(.6); color: #fff; }
    }
  }
}
