.block { padding: 30px 0; /*overflow: hidden;*/ position: relative;
  &.big-padding { padding: 60px 0; }
}

.bg { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; overflow: hidden;
  img { max-width: inherit; height: 100%; }
  &.color {
    &.default { background-color: $color-default; }
    &.dark { background-color: $color-grey-dark; }
    &.white { background-color: #fff; }
    &.neutral { background-color: $color-neutral; }
    &.black { background-color: #000; }
  }
}

.background-wrapper { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; overflow: hidden;
  img { width: 100%; height: auto; }
  .map { height: 100%; }
  .background-color { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -2;
    &.background-color-white { background-color: #fff; }
    &.background-color-black { background-color: #000; }
    &.background-color-default { background-color: $color-default; }
  }
}

.background-is-dark { color: #fff;
  a { color: #fff;
    &:hover, &:active, &:focus { color: #fff; }
    &.btn-default { color: #000;
    }
  }

  h1, h2, h3, h4, h5 { color: #fff; }

  .btn {
    &.btn-primary { /*background-color: darken( $color-default, 5% );*/ color: #fff; /*border-color: darken( $color-default, 5% );*/
      &:hover, &:active, &:active:hover, &:focus { /*background-color: darken($color-default,12%); border-color: darken($color-default,12%);*/ }
      &.btn-framed { color: #fff;
        &:hover, &:active, &:active:hover, &:focus { border-color: $color-default; }
      }
    }
  }

  .owl-dots {
    .owl-dot {
      span { background-color: #fff; }
    }
  }

  .ui-slider {
    .noUi-base { background-color: lighten($color-default, 20%);
      .noUi-connect { background-color: #fff; }
      .noUi-background { background-color: lighten($color-default, 20%); }
      .noUi-handle { border: 2px solid #fff; background-color: $color-default;
        &:hover, &.noUi-active { @include box-shadow(0px 0px 0px 8px rgba(#fff,.07)); }
      }
    }
    .values { color: #fff;
      input { background-color: transparent; border: none; width: 49%; }
    }
  }

}

.bg-transfer { background-size: cover; position: absolute; top: 0; left: 0; height: 100%; overflow: hidden; z-index: -1; width: 100%;
  img { display: none !important; }
}

.box { @include shadow; padding: 20px; position: relative; background-color: $color-grey-light; }

.center { text-align: center; }

.container {
  .block { padding: 30px; }
}

.label {
  &.label-info { background-color: $color-neutral; color: rgba(#000, .5); }
  &.label-danger { background-color: $color-red; }
}

.font-color-white { color: $color-white;
  &:hover, &:active, &:focus { color: $color-white !important;
    i { color: $color-white !important; }
  }
}
.font-color-black { color: #000; }
.font-color-default { color: $color-default; }
.font-color-dark { color: $color-grey-dark; }
.font-color-neutral { color: $color-neutral; }

.no-margin { margin: 0; }
.no-bottom-margin { margin-bottom: 0 !important; }
.no-top-margin { margin-top: 0; }
//.note { @include opacity(.5); font-size: 14px; }
.note { @include opacity(.5); @include transform-style(preserve-3d); color: #000; vertical-align: middle; font-size: 12px; margin: 5px 0; }

.half-bottom-margin { margin-bottom: 30px; }

.row.no-gutters { margin-right: 0; margin-left: 0; }
.row.no-gutters > [class^="col-"], .row.no-gutters > [class*=" col-"] { padding-right: 0; padding-left: 0; }

.opacity-90 { @include opacity(.9); }
.opacity-80 { @include opacity(.8); }
.opacity-70 { @include opacity(.7); }
.opacity-60 { @include opacity(.6); }
.opacity-50 { @include opacity(.5); }
.opacity-40 { @include opacity(.4); }
.opacity-30 { @include opacity(.3); }
.opacity-20 { @include opacity(.2); }
.opacity-19 { @include opacity(.19); }
.opacity-18 { @include opacity(.18); }
.opacity-17 { @include opacity(.17); }
.opacity-16 { @include opacity(.16); }
.opacity-15 { @include opacity(.15); }
.opacity-14 { @include opacity(.14); }
.opacity-13 { @include opacity(.13); }
.opacity-12 { @include opacity(.12); }
.opacity-11 { @include opacity(.11); }
.opacity-10 { @include opacity(.1); }
.opacity-9 { @include opacity(.09); }
.opacity-8 { @include opacity(.08); }
.opacity-7 { @include opacity(.07); }
.opacity-6 { @include opacity(.06); }
.opacity-5 { @include opacity(.05); }
.opacity-4 { @include opacity(.04); }
.opacity-3 { @include opacity(.03); }
.opacity-2 { @include opacity(.02); }
.opacity-1 { @include opacity(.01); }
.opacity-0 { @include opacity(.00); }

.overlay { position: relative;
  &:after { @include opacity(.4); background-color: #000; position: absolute; top: 0; left: 0; height: 100%; width: 100%; content: ""; }
}

.text-color-white { color: #fff !important;
  a { color: #fff; }
}
.text-align-right { text-align: right; }
.text-align-left { text-align: left; }

.vertical-aligned-elements { display: table; width: 100%; height: 100%; position: relative; z-index: 2;
  .element { display: table-cell; vertical-align: middle; }
}

.width-10 { width: 10% !important; }
.width-15 { width: 15% !important; }
.width-20 { width: 20% !important; }
.width-25 { width: 25% !important; }
.width-30 { width: 30% !important; }
.width-33 { width: 33% !important; }
.width-35 { width: 35% !important; }
.width-40 { width: 40% !important; }
.width-45 { width: 45% !important; }
.width-50 { width: 50% !important; }
.width-55 { width: 55% !important; }
.width-60 { width: 60% !important; }
.width-65 { width: 65% !important; }
.width-70 { width: 70% !important; }
.width-75 { width: 75% !important; }
.width-80 { width: 80% !important; }
.width-85 { width: 85% !important; }
.width-90 { width: 90% !important; }
.width-95 { width: 95% !important; }
.width-100 { width: 100% !important; }

.width-100px { width: 100px; }
.width-150px { width: 150px; }
.width-200px { width: 200px; }
.width-250px { width: 250px; }
.width-300px { width: 300px; }
.width-350px { width: 350px; }
.width-400px { width: 400px; }
.width-450px { width: 450px; }
.width-500px { width: 500px; }
.width-550px { width: 550px; }
.width-600px { width: 600px; }
.width-650px { width: 650px; }
.width-700px { width: 700px; }
.width-750px { width: 750px; }
.width-800px { width: 800px; }
.width-850px { width: 850px; }
.width-900px { width: 900px; }
.width-950px { width: 950px; }
.width-1000px { width: 1000px; }

.min-width-50px { min-width: 50px; }
.min-width-100px { min-width: 100px; }
.min-width-150px { min-width: 150px; }
.min-width-200px { min-width: 200px; }
.min-width-250px { min-width: 250px; }
.min-width-300px { min-width: 300px; }
.min-width-350px { min-width: 350px; }

.height-10percent { height: 10%; }
.height-20percent { height: 20%; }
.height-30percent { height: 30%; }
.height-40percent { height: 40%; }
.height-50percent { height: 50%; }
.height-60percent { height: 60%; }
.height-70percent { height: 70%; }
.height-80percent { height: 80%; }
.height-90percent { height: 90%; }
.height-100percent { height: 100%; }

.height-100px { height: 100px; }
.height-150px { height: 150px; }
.height-200px { height: 200px; }
.height-250px { height: 250px; }
.height-300px { height: 300px; }
.height-350px { height: 350px; }
.height-400px { height: 400px; }
.height-450px { height: 450px; }
.height-500px { height: 500px; }
.height-550px { height: 550px; }
.height-600px { height: 600px; }
.height-650px { height: 650px; }
.height-700px { height: 700px; }
.height-750px { height: 750px; }
.height-800px { height: 800px; }
.height-850px { height: 850px; }
.height-900px { height: 900px; }
.height-950px { height: 950px; }
.height-1000px { height: 1000px; }

.space { height: 50px; }

.shadow { @include shadow; }
.shadow-big { @include shadow-big; }